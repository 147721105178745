.App {
  text-align: left;
  padding:5px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-image: linear-gradient(#002230, #004663, #00587c);  
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(8px + 2vmin);
  color: white;
}

.App-link {
  color: #dffb61;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.messageDiv {
  padding: 10px;
  font-size: 15px;
  font-family: Lato,Verdana, Geneva, Tahoma, sans-serif;
}
