/* Global Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box; /* Ensure padding and border are included in the width/height */
}

body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    background-color: #002230;
}

/* Main container */
.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align content to the top */
    align-items: center;
    min-height: 100vh; /* Full viewport height */
    background-image: linear-gradient(#002230,#004663,#00587C); /* Dark background */
    color: white;
    padding: 10px; /* Add padding for better mobile experience */
    width: 100%; /* Ensure the container is always full-width */
}

/* Header with icon and title */
.headerContainer {
    text-align: center;
    margin-bottom: 20px;
    width: 100%; /* Ensure it takes the full width */
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 71px;  /* Set the desired width */
    height: 71px; /* Set the desired height */
    margin: 0 auto; /* Ensure the margin is 0 and centered horizontally */
    margin-top: 20px; /* Adjust the margin top as needed */
    background-color: #00587c; /* Set the background color similar to the screenshot */
    border-radius: 20px;  /* Rounds the corners */
    box-shadow: 2px 2px 4px rgba(137, 169, 187, 1.0);  /* Adds shadow similar to the screenshot */
}

.icon {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
}

.title {
    font-size: 20px;
    font-family: 'Lato', sans-serif;
    color: #89A9BB;
    text-align: center;
    margin-top: 20px;
}

/* Messages container */
.messagesContainer {
    background-color: #004663;
    padding: 20px;
    border-radius: 15px;
    max-width: 600px;
    width: 100%;
    margin-bottom: 20px;
    margin-left: 3px;
    margin-right: 3px;
    box-shadow: 2px 2px 4px 0px rgba(137, 169, 187, 1.0); /* Adds shadow similar to the screenshot */
    min-height: 100px; /* Smaller minimum height */
    overflow-y: visible; /* Allow the container to grow as needed */
    flex-grow: 1; /* Let the container grow with content */
}

/* Message bubbles */
.message {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    max-width: 93%;
    padding: 10px; /* Ensure there's enough padding inside the bubble */
    border-radius: 10px;
    font-size: 16px;
    word-wrap: break-word;
    font-family: 'Lato', sans-serif;
    line-height: 1.50em; /* Ensure readable line height */
    font-family: 'Lato',sans-serif;
}

/* User's message */
.messageUser {
    background-color: #63666a; /* Color for user messages */
    color: white;
    align-self: flex-end; /* Align the user's message to the right */
    margin-left: auto; /* Push the bubble to the right */
    max-width: 95%; /* Fit within the screen on mobile */
    font-size:15px;
}

/* Assistant's message */
.messageAssistant {
    background-color: #00587c; /* Color for assistant messages */
    color: white;
    align-self: flex-start; /* Align the assistant's message to the left */
    margin-right: auto; /* Push the bubble to the left */
    max-width: 95%; /* Fit within the screen on mobile */
    padding: 10px; /* Add more padding for better layout */
    word-wrap: break-word; /* Ensure long words or content breaks properly */
    font-size:15px;
    line-height: 1.5em;
}

/* Style lists (ul and ol) inside the assistant's message */
.messageAssistant ul,
.messageAssistant ol {
    padding-left: 20px; /* Add left padding to the list */
    margin-top: 10px;
    margin-bottom: 10px;
}

/* Style individual list items */
.messageAssistant li {
    margin-bottom: 5px; /* Add spacing between list items */
    line-height: 1.5em; /* Ensure good readability */
}

.messageAssistant p {
    margin-bottom: 15px; /* Add spacing between list items */
    line-height: 1.5em; /* Ensure good readability */
}



/* Ensure padding and wrapping in message content */
.messageContent {
    font-size: 16px;
    word-wrap: break-word; /* Ensure that the text wraps properly within the bubble */
    line-height: 1.5em;
}

/* Style links in the assistant's messages */
.messageAssistant a {
    color: #e1df45; /* Set the link color */
    text-decoration: underline; /* Remove underline if desired */
}

/* Input field and send button */
.inputContainer {
    display: flex;
    align-items: center;
    background-color: #004660;
    padding: 10px;
    border-radius: 15px;
    max-width: 600px;
    width: 100%;
    box-shadow: 2px 2px 4px rgba(137, 169, 187, 1.0); /* Adds shadow similar to the screenshot */
}

.textAreaInput {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-family: 'Lato',sans-serif;
    border: none;
    border-radius: 8px;
    background-color: white;
    color: #333333;
    outline: none;
    resize: none; /* Prevent the user from manually resizing */
    overflow-y: hidden; /* Hide the scroll while it grows */
    line-height: 1.5em;
    min-height: 40px; /* Set a minimum height */
    max-height: 480px; /* Optionally, limit how much it can grow */
}

.sendButton {
    background-color: #004460;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    margin-left: 10px;
    color: white;
    display: flex;
    align-items: center; /* Align icon and text vertically */
}

.sendButton img {
    width: 20px;
    height: 20px;
    color: white;
}

/* Footer */
.footer {
    text-align: center;
    font-size: 10px;
    color: #89A9BB;
    margin-top: 30px;
    width: 100%; /* Ensure the footer spans full width */
}

.footer a{
    
    color: #e1df45;
    text-decoration:underline;
}


.logo {
    margin-top:30px;
}

/* Download Container */
.downloadContainer {
    margin-top: 20px;
    text-align: center;
  }
  
.downloadButton {
    background-color: #00587c;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center; /* Align the icon and label vertically */
    justify-content: center;
    transition: background-color 0.3s ease; /* Add a smooth hover effect */
}

.downloadButton:hover {
    background-color: #004660;
}

/* Greeting Container */
.greetingContainer {
    background-color: #00587c;
    padding: 20px;
    border-radius: 15px;
    max-width: 600px;
    width: 100%;
    margin-bottom: 20px;
    margin-left: 3px;
    margin-right: 3px;
    box-shadow: 2px 2px 4px 0px rgba(137, 169, 187, 1.0); /* Adds shadow similar to other containers */
    color: white; /* Text color for the greeting */
    font-size: 16px; /* Font size */
    font-family: 'Lato', sans-serif;
    text-align: left; /* Align text to the left */
    line-height: 1.25em;
}

/* Loading Spinner */
.loadingContainer {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.loadingSpinner {
    border: 5px solid rgba(255, 255, 255, 0.3);
    border-top: 5px solid #89a9bb; /* Spinner color */
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
    .container {
        padding: 10px;
    }

    .greetingContainer,
    .messagesContainer,
    .inputContainer {
        padding: 10px;
        width: 100%;
    }

    .greetingContainer,
    .messagesContainer {
        max-width: 100%;
    }

    .messageUser,
    .messageAssistant {
        max-width: 90%; /* Make sure the message bubbles fit well on mobile */
    }

    .sendButton {
        padding: 8px;
        font-size: 16px;
    }

    .textAreaInput {
        font-size: 16px;
    }
}

/* Loading Bouncing Dots inside assistant's message bubble */
.loadingDotsMessage {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align the dots inside the assistant's message */
    background-color:#004460;
}

.bouncingDots {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 40px; /* Adjust the width of the dots container */
    height: 10px;
}

.bouncingDots div {
    width: 8px;
    height: 8px;
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    animation: bounce 1.4s infinite ease-in-out both;
    text-align:center;
}

.bouncingDots div:nth-child(1) {
    animation-delay: -0.32s;
}

.bouncingDots div:nth-child(2) {
    animation-delay: -0.16s;
}

@keyframes bounce {
    0%, 80%, 100% {
        transform: scale(0);
    } 
    40% {
        transform: scale(1);
    }
}

.suggestedQuestion {

    font-family: 'Lato', sans-serif;
    font-size: 16px;
    line-height: 1.25em;
    color: white;
    margin-top:30px;
    margin-bottom:30px;
    text-align:center;
}

.suggestedQuestion a{

    color: #e1df45;
    text-decoration:underline;
}

.resourceCard {
    background-color: #00587c;
    padding: 20px;
    border-radius: 13px;
    max-width: 600px;
    width: 100%;
    margin-bottom: 20px;
    margin-left: 3px;
    margin-right: 3px;
    box-shadow: 2px 2px 4px 0px rgba(137, 169, 187, 1.0); /* Adds shadow similar to other containers */
    color: #ffffff; /* Text color for the greeting */
    font-size: 16px; /* Font size */
    font-family: 'Lato', sans-serif;
    text-align: left; /* Align text to the left */
    line-height: 1.5em;
}

  .resourceCard a{
    color: #e1df45;
    text-decoration:underline;
    margin-top:15px;
    margin-bottom:15px;
  }
  
  
  .resourceCard h4{
    text-align: left; /* Ensure text remains left-aligned */
    margin-top:10px;
    margin-bottom:10px;
    width: 98%; /* Ensure the text takes up the full width of the container */
    font-weight:bold;
    font-size:17px;
  }

  .resourceCard p {
    text-align: left; /* Ensure text remains left-aligned */
    width: 98%; /* Ensure the text takes up the full width of the container */

  }

 .resourcesContainer{
    background-color:#004460;
 }

 .resourceMessage{
    background-color:#004460;
 }

 .resourceBackground {
    background-color:  #004460; /* Change to any color you prefer for resource messages */
  }
  
  .resourceCard img {
        display: block;
        max-width:150px;
        max-height:100px;
        width: auto;
        height: auto;
        margin: 0 auto;
        border-radius:8px;
  }
  
